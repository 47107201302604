.cartscreen {
  display: flex;
  max-width: 1300px;
  margin: 2rem auto;
}

.cartscreen h2 {
  margin-bottom: 1rem;
}

.cartscreen__left {
  flex: 0.7;
  margin-right: 1rem;
  background: transparent;
  padding: 1rem;
}

.cartscreen__right {
  flex: 0.3;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  height: fit-content;
}

.cartscreen__right div {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem;
}

.cartscreen__info p {
  padding: 8px;
}

.cartscreen__info p:first-child {
  font-weight: bold;
}

.cartscreen__right div:last-child {
  border: none;
}

.cartscreen__right div button {
  padding: 10px 17px;
  width: 100%;
  background: #171717;
  color: #f4f4f4;
  border: 1px solid #171717;
  cursor: pointer;
}

.cartscreen__right div button:hover {
  opacity: 0.9;
}

@media (max-width: 1320px) {
  .cartscreen {
    max-width: 900px;
  }
}

@media (max-width: 960px) {
  .cartscreen {
    max-width: 800px;
  }
}

@media (max-width: 960px) {
  .cartscreen {
    flex-direction: column;
  }

  .cartscreen__right {
    margin: 1rem;
  }
}


.cart {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  border-radius: 30px;
  background-color: #ffffff;
  /* box-shadow: 15px 15px 30px 0px rgb(0 0 0 / 7%), -15px -15px 30px 0px rgb(255 255 255 / 80%); */
  padding: 30px;
  min-height: 404px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}


.cart__table {
  width: 100%;
  min-width: 500px;
  margin-bottom: 15px;
}

.cart__table th {
  font-size: 16px;
  font-weight: 600;
  color: rgba(55,55,55,0.7);
  line-height: 100%;
  padding: 0 25px 15px 0;
}

.cart__table td:last-child {
  width: 24px;
  padding: 15px 0;
}

.cart__table td {
  font-size: 16px;
  font-weight: 400;
  color: #373737;
  padding: 15px 25px 15px 0;
}

.cart__empty {
  position: relative;
  background-color: #f0f0f0;
  margin-bottom: 0;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 136px;
  background-color: #f0f0f0;
  /* box-shadow: inset -4px -4px 8px 0px rgb(255 255 255 / 70%), inset 4px 4px 8px 0px rgb(0 0 0 / 5%); */
  font-size: 14px;
  color: rgba(55,55,55,0.7);
  font-weight: 400;
  cursor: default;
}


.cart__img {
  width: 96px;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cart__img img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.cart__quantity {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* box-shadow: inset -3px -3px 6px 0px rgb(255 255 255 / 73%), inset 3px 3px 6px 0px rgb(0 0 0 / 8%); */
  border-radius: 30px;
  padding: 0 5px;
}

/* .cart__quantity button {
   display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  color: rgba(55,55,55,0.6);
  font-size: 20px;
  border-radius: 50%; 
  background-color: transparent;
} */

.cart__quantity input {
  height: 34px;
  width: 24px;
  text-align: center;
  font-size: 14px;
  color: #373737;
  border: none;
  pointer-events: none;
  cursor: default;
  background-color: transparent;
}


.cart-right{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  border-radius: 30px;
  background-color: #ffffff;
  /* box-shadow: 15px 15px 30px 0px rgb(0 0 0 / 7%), -15px -15px 30px 0px rgb(255 255 255 / 80%); */
  padding: 30px;
}


.cart__total {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.cart__total p {
  font-size: 14px;
  color: #373737;
  margin-bottom: 10px;
}

.cart__total span {
  font-size: 24px;
  color: #373737;
  line-height: 100%;
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
}